import React, { Component } from "react";
import Parallax     from "../components/Parallax/Parallax";
import Services     from "../components/Services/Services";
import AboutPreview from "../components/AboutPreview/AboutPreview";
import Featured     from "../components/Featured/Featured";
import Availability from "../components/Availability/Availability";

class HomePage extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return(
      <div>
        <Parallax />
        <Services />
        <AboutPreview />
        <Featured />
        <Availability />
      </div>
    )
  }
}

export default HomePage
