import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Link }             from "react-router-dom";
import "./header.css";

class Header extends Component {

  render() {
    return(
      <header>
        <div
          className="go-back"
          onClick={ () => this.props.history.goBack() }
          >
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div className="heading">
          <h2><Link to={ "/" }>Eva Galen | { this.props.pageTitle }</Link></h2>
        </div>
      </header>
    )
  }
}

export default withRouter(Header)
