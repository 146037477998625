import React, { Component } from "react";
import { Link }             from "react-router-dom";
import "./footer.css";

class Footer extends Component {

  render() {
    return(
      <footer>
        <div className="logo">
          <h3><Link to={ "/" }>E.G.</Link></h3>
        </div>
        <div className="contact">
          <h3><Link to={ "/" }>HOME</Link></h3>
          <h2 className="separator">|</h2>
          <h3><Link to={ "/about/developer" }>ABOUT</Link></h3>
          <h2 className="separator">|</h2>
          <h3><Link to={ "/portfolio" }>PORTFOLIO</Link></h3>
          <h2 className="separator">|</h2>
          <h3 onClick={ this.props.toggleContactWindow }>CONTACT</h3>
        </div>

        <div className="social-media">
          <a
            href="https://www.credential.net/zadphebj"
            target="_blank"
            rel="noopener noreferrer"
            >
            <div className="social-icon certification" ></div>
            </a>
          <a
            href="https://github.com/levatech007"
            target="_blank"
            rel="noopener noreferrer"
            >
            <div className="social-icon github"></div>
          </a>
          <a
            href="https://www.linkedin.com/in/evaliisagalen"
            target="_blank"
            rel="noopener noreferrer"
            >
            <div className="social-icon linkedin"></div>
          </a>
        </div>
        <div className="copyright">
          <p>&copy; 2018 - { (new Date()).getFullYear() } Eva Galen</p>
        </div>
      </footer>
    )
  }
}

export default Footer
