import React, { Component } from "react";
import "./parallax.css";

class Parallax extends Component {

  componentDidMount() {
    window.addEventListener("scroll", this.handleScrollSpeed )
  }

  handleScrollSpeed() {
    let parallaxContainer = document.querySelector('.parallax-container');
    let parallaxLayers = parallaxContainer.getElementsByTagName('div');
    for(let i = 0; i < parallaxLayers.length; i++) {
      parallaxLayers[i].style.transform = `translateY(-${(window.pageYOffset * i / parallaxLayers.length)}px)`;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollSpeed);
  }

  render() {
    return(
        <section className="parallax-container">
         <div id="parallax1"></div>
         <div id="parallax2"></div>
         <div id="parallax3"></div>
         <div id="parallax4"></div>
        <div className="hero-content">
         <h1>Eva Galen</h1>
         <p>Full Stack Web Developer</p>
       </div>
      </section>
    )
  }
}

export default Parallax
