import React, { Component } from "react";
import Header               from "../components/Header/Header";
import Tabs                 from "../components/Tabs/Tabs";
import TabsContent          from "../components/Tabs/TabsContent";
import AboutContent         from "../page-data/about-page.json";

class AboutPage extends Component {
  constructor() {
    super()
    this.state = {
      tabs: [],
      activeTab: "",
      tabsContent: "",
    }
    this.handleTabsClick = this.handleTabsClick.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    // set the keys as tabs
    let tabs = Object.keys(AboutContent)
    // set the first tab in array as active tab if there is no prop sent
    let activeTab = this.props.match.params.active; // either prop sent from onClick or first by default
    this.setState({
      tabsContent: AboutContent,
      activeTab: tabs[0],
      tabs: tabs,
    })
  }

  handleTabsClick(activeTab) {
    this.setState({
      activeTab: activeTab
    })
  }

  render() {
    return(
        <div className="about-page">
          <Header pageTitle={ "About" }/>
          <div className="about-content">
            <div className="portrait">
              <img src={ require("../images/portrait.png") } alt="portrait" />
            </div>
            <div className="quote">
              <i>'I love being a web developer because it pushes the boundaries of
              my knowledge, it tests my determination but most of all, it lets
              me be a part of the future.'</i>
            </div>
            { this.state.activeTab &&
              <Tabs
                handleTabsClick={ this.handleTabsClick }
                tabs={ this.state.tabs }
                activeTab={ this.state.activeTab  }
              />
            }
            { this.state.activeTab &&
              <TabsContent
                tabContent={ this.state.tabsContent[this.state.activeTab] }
              />
            }
          </div>
        </div>
    )
  }
}

export default AboutPage
