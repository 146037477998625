import React, { Component } from "react";
import { Link }             from "react-router-dom";
import "./hamburger-menu.css";

class HamburgerMenu extends Component {
  constructor() {
    super();
    this.state = {
      showNavMenu: false,
    }
    this.toggleNavMenu = this.toggleNavMenu.bind(this);
  }

  toggleNavMenu() {
    // turn hamburger menu to 'X' and show nav menu box
    this.setState({ showNavMenu: !this.state.showNavMenu })
  }

  renderNavMenu() {
    return(
      <div className="nav-menu">
        <div className="menu-link" onClick={ this.toggleNavMenu } >
          <Link to={ "/" }>HOME</Link>
        </div>
        <div className="menu-link" onClick={ this.toggleNavMenu } >
          <Link to={ "/portfolio" }>PORTFOLIO</Link>
        </div>
        <div className="menu-link" onClick={ this.toggleNavMenu } >
          <Link to={ "/about/developer" }>ABOUT</Link>
        </div>
        <div className="menu-link" onClick={ this.toggleNavMenu } >
          <p onClick={ this.props.openContactWindow }>CONTACT</p>
        </div>
      </div>
    )
  }

  render() {
    return(
        <nav>
          { this.state.showNavMenu ? this.renderNavMenu() : null }
          <div
          className={ this.state.showNavMenu ? "hamburger-menu open" : "hamburger-menu" }
          onClick={ this.toggleNavMenu }>
          <span></span>
          <span></span>
          <span></span>
        </div>
        </nav>
    )
  }
}

export default HamburgerMenu
