import React, { Component } from "react";
import ContactForm from "../../components/Contact/ContactForm";
import "./availability.css";

class Availablilty extends Component {
  constructor() {
    super();
    this.state = {
      showContactWindow: false,
    }
    this.toggleContactWindow = this.toggleContactWindow.bind(this);
  }

  toggleContactWindow() {
    this.setState({
      showContactWindow: !this.state.showContactWindow
    })
  }

  render() {
    return(
      <section className="availablilty preview-section">
      { this.state.showContactWindow ? <ContactForm close={ this.toggleContactWindow } /> : null }
        <div className="section-content section-text">
          <h1>Hire me.</h1>
          <p>Need help with a project? Just want to say 'hello'?</p>
          <button onClick={ this.toggleContactWindow } className="avail-btn">Contact</button>
        </div>
      </section>
    )
  }
}

export default Availablilty
