import React, { Component } from "react";
import ReCAPTCHA    from "react-google-recaptcha";
import Contact      from "./Contact.js";
import Alert        from "../../components/Alert/Alert";
import * as emailjs from "emailjs-com";
import "./contact-form.css";


class ContactForm extends Component {
  constructor() {
      super();
      this.state = {
        name:   "",
        email:  "",
        message:   "",
        showAlert:    false,
        alertMessages: [],
        alertStyle:   "",
        recaptchaResponse: "",
        formSubmitted:     false,
        inputFields:       ["name", "email"],
      }
      this.handleInputChange    = this.handleInputChange.bind(this);
      this.validateFormInputs   = this.validateFormInputs.bind(this);
      this.handleFormSubmission = this.handleFormSubmission.bind(this);
      this.onRecaptchaChange    = this.onRecaptchaChange.bind(this);
      this.renderContactForm    = this.renderContactForm.bind(this);
  }

  handleInputChange(e) {
    e.preventDefault()
    const name   = e.target.name
    const value  = e.target.value

    this.setState({ [name]: value })
  }

  validateFormInputs() {
    let alertMessages = []
    let formIsValid = true
    if (!this.state.name) {
      formIsValid = false
      alertMessages.push("Name must be present.")
    }
    const validEmailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if(this.state.email) {
      if (!validEmailPattern.test(this.state.email)) {
        formIsValid = false
        alertMessages.push("Email is not valid.")
      }
    } else {
      formIsValid = false
      alertMessages.push("Email must be present.")
    }
    if (!this.state.message) {
      formIsValid = false
      alertMessages.push("Message must be present.")
    }
    this.setState({ alertMessages: alertMessages })
    return formIsValid
  }

  handleFormSubmission(e) {
    e.preventDefault();
    if (this.state.recaptchaResponse) {
      if(this.validateFormInputs()) {
        let messageParams = {
           "senderEmail": this.state.email,
           "senderName": this.state.name,
           "senderMessage": this.state.message,
          }
        let userId     = process.env.REACT_APP_EMAILJS_USERID
        let serviceId  = process.env.REACT_APP_EMAILJS_SERVICEID
        let templateId = process.env.REACT_APP_EMAILJS_TEMPLATEID

        emailjs.send(serviceId, templateId, messageParams, userId)
        .then((response) => {
          this.setState({
            name:          "",
            email:         "",
            message:       "",
            showAlert:     true,
            alertStyle:    "alert-box ok",
            alertMessages: ["Thank you! Your request has been submitted!"],
            formSubmitted: true,
          })
        }, (err) => {
          this.setState({
            showAlert:     true,
            alertStyle:    "alert-box error",
            alertMessages: ["Oops...something went wrong! Please try again."],
            formSubmitted: false,
          })
        })

      } else { // form not valid
          this.setState({
                          name:              "",
                          email:             "",
                          message:           "",
                          showAlert:         true,
                          alertMessages:     this.state.alertMessages,
                          alertStyle:        "alert-box error",
                          recaptchaResponse: this.state.recaptchaResponse,
                          formSubmitted:     false,
                        })
      }
    } else { // recaptcha not valid
      this.setState({
                      name:   this.state.name,
                      email:  this.state.email,
                      message: this.state.message,
                      showAlert:    true,
                      alertMessages: ["Please check the Recaptcha box."],
                      alertStyle:   "alert-box error",
                      recaptchaResponse: "",
                      formSubmitted:     false,
                    })
    }
  }

  onRecaptchaChange(response) {
    this.setState({
      recaptchaResponse: response,
    });
  }

  renderContactForm() {
    return(
          <form>
            { this.state.showAlert ? <Alert alert={ this.state.alertMessages } alertStyle={ this.state.alertStyle } /> : null }
            <div className="row">
            {
              this.state.inputFields.map((field, idx) => {
                let labelName = `${ field.charAt(0).toUpperCase() }${ field.slice(1) }`
                return(
                    <div className="col-50" key={ idx }>
                      <label>{ labelName }<span className="red-text">*</span></label>
                      <input
                        type={ (field === 'email') ? "email" : "text" }
                        name={ field }
                        required="required"
                        placeholder={ `Enter your ${ field }` }
                        onChange={ this.handleInputChange }
                        value={ this.state[field] }
                      />
                    </div>
                )
              })
            }
            </div>
            <div className="row">
              <label>Message<span className="red-text">*</span></label>
            </div>
            <div className="row">
              <textarea
                name="message"
                rows="5"
                placeholder="Your message here"
                required="required"
                onChange={ this.handleInputChange }
                value={ this.state.message }
                >
                </textarea>
              </div>
            <div className="row justify-content-center">
              <ReCAPTCHA
                ref="recaptcha"
                sitekey={ process.env.REACT_APP_SITE_KEY }
                onChange={ this.onRecaptchaChange }/>
            </div>
        </form>
    )
  }

  render() {
    return(
      <Contact
        contactForm={ this.renderContactForm() }
        submit={ this.handleFormSubmission }
        close={ this.props.close }
      />
    )
  }
}

export default ContactForm;
