import React, { Component } from 'react';
import MyRoutes from "./config/routes.js";
import HamburgerMenu from "./components/HamburgerMenu/HamburgerMenu";
import Footer from "./components/Footer/Footer";
import ContactForm from "./components/Contact/ContactForm"
import './App.css';

class App extends Component {
  constructor() {
    super();
    this.state = {
      showContactWindow: false,
    }
    this.toggleContactWindow = this.toggleContactWindow.bind(this);
  }

  toggleContactWindow() {
    this.setState({
      showContactWindow: !this.state.showContactWindow
    })
  }

  render() {
  return (
    <div>
      <HamburgerMenu openContactWindow={ this.toggleContactWindow }/>
      { MyRoutes }
      <Footer toggleContactWindow={ this.toggleContactWindow }/>
      { this.state.showContactWindow ? <ContactForm close={ this.toggleContactWindow } /> : null }
    </div>
    )
  }
}

export default App;
