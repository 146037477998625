import React, { Component } from "react";
import Header               from "../components/Header/Header";
import OnePortfolioProject  from "../components/OnePortfolioProject/OnePortfolioProject";
import PortfolioData        from "../page-data/portfolio-data.json";

class PortfolioPage extends Component {
  constructor() {
    super()
    this.state = {
      pageData: [],
      extendedContentIdx: null,
      extendedContentIdxs: [],
    }
    this.toggleExtendedContent = this.toggleExtendedContent.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setState({ pageData: PortfolioData.portfolio })
  }

  toggleExtendedContent(idx) {
    if(this.state.extendedContentIdxs.includes(idx)) {
      let removeIndex = this.state.extendedContentIdxs.indexOf(idx)
      if (removeIndex > -1) {
        this.state.extendedContentIdxs.splice(removeIndex, 1)
        this.setState({
          extendedContentIdxs: this.state.extendedContentIdxs
        })
      }
    } else {
      this.setState({
        extendedContentIdxs: this.state.extendedContentIdxs.concat(idx)
      })
    }
  }

  render() {
    return(
      <section className="portfolio-page">
        <Header pageTitle={ "Portfolio" }/>
        {
          this.state.pageData.map((project, idx) => {
            return(
              <OnePortfolioProject
                key={ idx }
                idx={ idx }
                project={ project }
                extendedContentClick={ this.toggleExtendedContent }
                extendedContentIdx={ this.state.extendedContentIdx }
                showExtendedContent={ this.state.extendedContentIdxs.includes(idx) ? true : false }
              />
            )
          })
        }

      </section>
    )
  }
}

export default PortfolioPage
