import React, { Component } from "react";
// import ContactForm from "./ContactForm";
import "./contact.css";

class Contact extends Component {
  constructor() {
    super()
    this.handleWindowClick = this.handleWindowClick.bind(this);
  }

  handleWindowClick(e) {
    // close window if user clicked on the background outside the contact-window
    e.preventDefault()
    if(e.target.className === "contact-screen")
      this.props.close()
  }

  render() {
    return(
      <div className="contact-screen" onClick={ this.handleWindowClick }>
        <div className="contact-window">
          <div className="contact-content">
            <div className="close-x" onClick={ this.props.close }>
              <span></span>
              <span></span>
            </div>
            <h1>CONTACT</h1>
            { this.props.contactForm }
            <div className="row justify-content-center">
              <button className="send-btn" type="submit" onClick={ this.props.submit }>Send</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact
