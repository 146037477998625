import React, { Component } from "react";
import "./one-portfolio-project.css";

class OnePortfolioProject extends Component {
  constructor() {
    super()
    this.state = {
      extendedContentClass: "portfolio-window",
      extendedContentAnimation: "fade-in"
    }
    this.toggleExtendedContent = this.toggleExtendedContent.bind(this)
    this.renderRightArrow      = this.renderRightArrow.bind(this);
    this.renderLeftArrow       = this.renderLeftArrow.bind(this);
  }

  toggleExtendedContent(direction) {
    // change class to trigger animations depending on which arrow is clicked
    if(direction === "left") {
      this.setState({
        extendedContentClass: "slide-close portfolio-window",
        extendedContentAnimation: "fade-out"
       })
      // if window need to close, set timeout to 4s (animation duration) before turning off extended contact visibility
      setTimeout(()=> this.props.extendedContentClick(this.props.idx), 4000)
      // reset class back to default after animation has completed
      setTimeout(()=> this.setState({ extendedContentClass: "portfolio-window" }), 4001)
    } else if(direction === "right") {
        this.props.extendedContentClick(this.props.idx)
        this.setState({
          extendedContentClass: "portfolio-window slide-open",
          extendedContentAnimation: "fade-in"
         })
    } else {
      // no animations are necessary for mobile or small screen view (< 800px)
      this.props.extendedContentClick(this.props.idx)
      this.setState({
        extendedContentClass: "portfolio-window",
        extendedContentAnimation: "",
      })
    }
  }

  renderRightArrow() {
    return(
      <div
        onClick={ () => this.toggleExtendedContent("right") }
        className="see-more-arrow right" >
        <span></span>
        <span></span>
      </div>
    )
  }

  renderLeftArrow() {
    return(
      <div
        onClick={ () => this.toggleExtendedContent("left") }
        className={ `see-more-arrow left ${ this.state.extendedContentAnimation }` }
      >
        <span></span>
        <span></span>
      </div>
    )
  }

  render() {
    const imageUrl = require(`./images/${ this.props.project.projectBackgroundImage }`)
    return(
      <div
        className="portfolio-container"
        style={{
          backgroundImage: `url(${ imageUrl })`
        }}>
          <div className={ this.state.extendedContentClass }>
            <div className="portfolio-content">
              <div className="left-content">
                <div className="project-image">
                  <img src={ require(`./images/${ this.props.project.projectDisplayImage }`) } alt={ this.props.project.projectName } />
                </div>
                <div className="portfolio-details">
                  <h1>{ this.props.project.projectName }</h1>
                  <p>{ this.props.project.projectIntro }</p>
                  <p>Built with { this.props.project.projectTechnologies }</p>
                  <a
                    href={ this.props.project.siteLink }
                    target="_blank"
                    rel="noopener noreferrer"
                    >LIVE SITE</a>
                    { this.props.project.githubLink ?
                      <a
                        href={ this.props.project.githubLink }
                        target="_blank"
                        rel="noopener noreferrer"
                        >GITHUB</a>
                        :
                        null
                      }
                </div>
              </div>

              { this.props.showExtendedContent ?
                <div className={ `right-content ${ this.state.extendedContentAnimation }` }>
                  {
                    Object.keys(this.props.project.projectDescription).map(key =>{
                      return(
                        <p key={ key }>{ this.props.project.projectDescription[key] }</p>
                      )
                    })
                  }
              </div> : null }
            { this.props.showExtendedContent ? this.renderLeftArrow() : this.renderRightArrow() }
            <div className="extended-content" onClick={ () => this.toggleExtendedContent("mobile") }>
              <p>{ this.props.showExtendedContent ? "Show less..." : "Show more..." }</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OnePortfolioProject
