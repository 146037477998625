import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./services.css";

class Services extends Component {
  constructor() {
    super();
    this.state = {
      icons: [
        {id: "frontend", title: "Front End Development", link: "developer"},
        {id: "backend", title: "Back End Development", link: "developer"},
        {id: "design", title: "Graphic Design", link: "designer"},
        {id: "tools", title: "Site Management", link: "developer" }
      ],
      selectionId: "",
      showHoverIcon: false
    }
    this.addHoverImage    = this.addHoverImage.bind(this);
    this.removeHoverImage = this.removeHoverImage.bind(this);
  }

  addHoverImage(id) {
    this.setState({
      selectionId: id,
      showHoverIcon: true,
     })
  }

  removeHoverImage() {
    this.setState({
      selectionId: "",
      showHoverIcon: false,
     })
  }

  render() {
    return(
      <section className="services">
        <div className="section-content">
        {
          this.state.icons.map((icon, idx) => {
            return(
              <Link to={ `/about/${ icon.link }` } key={ idx }>
              <div
                className="menu-icon"
                onMouseEnter={ () => this.addHoverImage(icon.id) }
                onMouseLeave={ this.removeHoverImage }
              >
                <img
                  src={ require(`./images/${ icon.id }-icon-${ this.state.showHoverIcon && this.state.selectionId === icon.id ? "white" : "gray" }.svg`) }
                  alt={ icon.id }/>
                <h3>{ icon.title }</h3>
              </div>
            </Link>
            )
          })
        }
      </div>
      </section>
    )
  }
}

export default Services
