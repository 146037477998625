import React, { Component } from "react";
import { Link }             from "react-router-dom";
import "./about-preview.css";

class AboutPreview extends Component {
  render() {
    return(
      <section className="about-preview preview-section">
        <div className="section-content section-text">
        <h1>Hi.</h1>
        <p>I am a full stack web developer. I design and build clean, simple and
          modern React front-end interfaces and Ruby back-end APIs. <Link to={ "/about/developer" }>More...</Link></p>
      </div>
      </section>
    )
  }
}

export default AboutPreview
