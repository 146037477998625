import React, { Component } from "react";
import './tabs.css'

class Tabs extends Component {
  constructor() {
    super();

    this.handleTabClick = this.handleTabClick.bind(this);
  }

  handleTabClick(tab) {
    this.props.handleTabsClick(tab)
  }

  render() {
    return(
      <div className="tabs">
        {
          this.props.tabs.map((tab, idx) => {
            return(
              <div
                className={ this.props.activeTab === tab ? "tab tab-active" : "tab" }
                onClick={ () => this.handleTabClick(tab) }
                key={ idx }
              >
              <p>{ `${ tab.charAt(0).toUpperCase() }${ tab.slice(1) }` }</p>
              </div>
            )
          })
        }
      </div>
    )
  }
}

export default Tabs
