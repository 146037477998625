import React              from "react";
import { Switch, Route }  from "react-router-dom";
import HomePage           from "../containers/HomePage.js";
import PortfolioPage      from "../containers/PortfolioPage.js";
import AboutPage          from "../containers/AboutPage.js";

export default (
  <Switch>
      <Route exact path="/" component={ HomePage }/>
      <Route exact path="/portfolio" component={ PortfolioPage }/>
      <Route exact path="/about/:active" component={ AboutPage }/>
  </Switch>
)
