import React, { Component } from "react";
import './tabs.css'

class TabsContent extends Component {
  constructor() {
    super()
    this.renderList = this.renderList.bind(this);
  }

  renderList() {
    return(
      <ul>
        {
          this.props.tabContent.services.map((item, idx) => {
            return(
              <li key={ idx }>&#8226; &nbsp; { item }</li>
            )
          })
        }
      </ul>
    )
  }

  render() {
    return(
      <div className="tabs-content content-fade-in">
        <h3>{ this.props.tabContent.title1 }</h3>
      { this.props.tabContent.services ?  this.renderList() : null }

        <h3>{ this.props.tabContent.title2 }</h3>
        {
          this.props.tabContent.skills ?
          <div className="skills">
            {
              this.props.tabContent.skills.map((skill, idx)=> {
                return(
                  <p key={ `${idx}a` }><b>{ skill.title }:</b></p>
                )
              })
            }
          </div>
          :
          null
        }
        {
          this.props.tabContent.skills ?
          <div className="skills">
            {
              this.props.tabContent.skills.map((skill, idx)=> {
                return(
                  <p key={ `${idx}b` }>{ skill.content }</p>
                )
              })
            }
          </div>
          :
          null
        }


        {
          this.props.tabContent.skills ?
          <div className="skill-list-mobile">
            {
              this.props.tabContent.skills.map((skill, idx)=> {
                return(
                  <div className="skill" key={ `${idx}c` }>
                  <p><b>{ skill.title }:</b></p>
                  <p>{ skill.content }</p>
                </div>
                )
              })
            }
          </div>
          :
          null
        }
        <h3>{ this.props.tabContent.title3 }</h3>
        <p>{ this.props.tabContent.paragraphOne }</p>
        { this.props.tabContent.paragraphTwo ?
          <p>{ this.props.tabContent.paragraphTwo }</p> : null
        }
        { this.props.tabContent.certificationLink ? <img src={this.props.tabContent.certificationLink}/> : null }
      </div>
    )
  }
}

export default TabsContent
