import React, { Component } from "react";
import { Link }             from "react-router-dom";
import "./featured.css";

class Featured extends Component {
  render() {
    return(
      <section className="featured preview-section">
        <div className="featured-content section-content">
          <h1>Featured.</h1>
          <Link to={ "/portfolio" }><img src={ require("./featured-project.svg") } alt="featured-project" /></Link>
          <p>Volo is the first app for plane planespotters that allows users to filter flights based on aircraft type.</p>
          <Link to={ "/portfolio" }>See more projects</Link>
        </div>
      </section>
    )
  }
}

export default Featured
